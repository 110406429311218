<!-- Footer -->
<p class="clearfix mb-0">
  <span class="float-md-right d-block d-md-inline-block mt-25">
    COPYRIGHT &copy; {{ year }}
    <a class="ml-25" href="https://telenavis.com/Ευανάγνωστο" target="_blank">Euanagnosto team</a>
    <span class="d-none d-sm-inline-block">, All rights Reserved</span>
    <span *ngIf="!enviroment.production" class="d-none d-sm-inline-block">, {{enviroment.mode}}</span>
  </span>
  <!-- <span class="float-md-right d-none d-md-block">
    <span style="margin-right: -4px !important;">Hand-crafted & Made with</span> <span [data-feather]="'heart'" [class]="'pink'"></span> by <strong>Telenavis</strong>
  </span> -->
</p>
<!--/ Footer -->

