<div class="content-wrapper container-xxl p-0">
    <div class="content-body">

        <app-content-header [contentHeader]="contentHeader"></app-content-header>

        <!-- Language selection -->
        <label>Πανεπιστήμιο</label>
        <li ngbDropdown class="nav-item dropdown dropdown-language"
            style="list-style: none; border-style: solid; border-width: 1px; border-radius: 4px; border-color: lightgray; padding: 2px;">
            <a class="nav-link dropdown-toggle" id="dropdown-lesson" ngbDropdownToggle>
                <span class="selected-language">{{ returnLesson() }}</span></a>
            <div ngbDropdownMenu aria-labelledby="dropdown-lesson"
                style="max-height: 20vh !important; overflow-y: auto;">
                <div *ngFor="let lesson of universities">
                    <a ngbDropdownItem (click)="selectLesson(lesson.value)">{{lesson.key}}</a>
                </div>
            </div>
        </li>
        <!--/ Language selection -->
    </div>
</div>