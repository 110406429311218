import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { of } from 'rxjs';
import { timeout, catchError, map } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  // domain_local: string = `http://127.0.0.1:3000`
  domain_local: string = environment.apiUrl;
  domain2 = "http://167.172.194.214:333";

  constructor(
    private http: HttpClient,
  ) { }

  // ********************************** STORED DATA ***********************************
  arrayOfHeaders: Array<any> = [];

  jobsDetails: any = {};
  jobsDetailsHeader: any = {};
  
  // ********************************** GENERAL FUNCTION ***********************************
  
  loginProfile(params: any) {
    return this.http.post(this.domain_local + `/index/login`, params)
  }
  putarticletext(params: any) {
    return this.http.put(this.domain_local + `/index/putarticletext`, params)
  }
  putarticlename(params: any) {
    return this.http.put(this.domain_local + `/index/putarticlename`, params)
  }
  addarticle(params: any) {
    return this.http.post(this.domain_local + `/index/addarticle`, params)
  }
  addthemata(params: any) {
    return this.http.post(this.domain_local + `/pdf/addthemata_v2`, params)
  }
  getallarticles(params: any) {
    return this.http.get(this.domain_local + `/index/getallarticles?padding=`+params.padding)
  }
  getarticlebyid(params: any) {
    return this.http.get(this.domain_local + `/index/getarticlebyid?id=`+params.id)
  }
  deletearticle(params: any) {
    return this.http.delete(this.domain_local + `/index/deletearticle?id=`+params.id)
  }

  // ********************************** GENERAL FUNCTION ***********************************
  callFunction(request:string, params: any){
    return new Promise(async (resolve, reject)=>{
      let response: any = {}

      if (request=='loginProfile'){ response = this.loginProfile(params) }
      else if (request=='getallarticles'){ response = this.getallarticles(params) }
      else if (request=='getarticlebyid'){ response = this.getarticlebyid(params) }
      else if (request=='addarticle'){ response = this.addarticle(params) }
      else if (request=='deletearticle'){ response = this.deletearticle(params) }
      else if (request=='putarticletext'){ response = this.putarticletext(params) }
      else if (request=='putarticlename'){ response = this.putarticlename(params) }
      else if (request=='addthemata'){ response = this.addthemata(params) }
      
      response.pipe(timeout(1000*60*30), catchError(e=> {return of(null)})).subscribe(
        async (res: any) => {
          if(res==null){
            console.log(1, "Message!", "We cannot communicate with server! Timeout error!", "Close")
          }else{
            resolve(res)
          }
        },
        (er: any) => { console.error(er) }
      );
    })
  }
}
