import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-universities',
  templateUrl: './universities.component.html',
  styleUrls: ['./universities.component.scss']
})
export class UniversitiesComponent implements OnInit {

  constructor() { }

  public contentHeader: object = {
    headerTitle: "Επιλογη Πανεπιστημίου",
    actionButton: true,
    breadcrumb: {
      type: '',
      links: [
        {
          name: "Νέο"
        }
      ]
    }
  };

  universities: Array<any> = 
  [
    { "key": 'ΑΡΙΣΤΟΤΕΛΕΙΟ ΠΑΝΕΠΙΣΤΗΜΙΟ ΘΕΣΣΑΛΟΝΙΚΗΣ', "value": 'https://www.auth.gr/' },
    { "key": 'ΔΙΕΘΝΕΣ ΠΑΝΕΠΙΣΤΗΜΙΟ ΕΛΛΑΔΟΣ', "value": 'https://www.ihu.gr/ucips/' },
    { "key": 'ΕΛΛΗΝΙΚΟ ΑΝΟΙΚΤΟ ΠΑΝΕΠΙΣΤΗΜΙΟ', "value": 'https://www.eap.gr/el/' },
    { "key": 'ΟΙΚΟΝΟΜΙΚΟ ΠΑΝΕΠΙΣΤΗΜΙΟ ΑΘΗΝΩΝ', "value": 'https://www.aueb.gr/' },
    { "key": 'ΠΑΝΕΠΙΣΤΗΜΙΟ ΔΥΤΙΚΗΣ ΜΑΚΕΔΟΝΙΑΣ', "value": 'https://uowm.gr/' },
    { "key": 'ΠΑΝΕΠΙΣΤΗΜΙΟ ΚΡΗΤΗΣ', "value": 'https://www.uoc.gr/' },
    { "key": 'ΠΑΝΕΠΙΣΤΗΜΙΟ ΠΕΙΡΑΙΑ', "value": 'https://www.unipi.gr/unipi/el/' },
    { "key": 'ΠΟΛΥΤΕΧΝΕΙΟ ΚΡΗΤΗΣ', "value": 'https://www.tuc.gr/index.php?id=4992' },
    { "key": 'ΑΝΩΤΑΤΕΣ ΣΧΟΛΕΣ ΠΑΙΔΑΓΩΓΙΚΗΣ & ΤΕΧΝΟΛΟΓΙΚΗΣ ΕΚΠΑΙΔΕΥΣΗΣ (ΑΣΠΑΙΤΕ)', "value": 'https://www.aspete.gr/index.php/el/' },
    { "key": 'ΑΝΩΤΑΤΗ ΕΚΚΛΗΣΙΑΣΤΙΚΗ ΑΚΑΔΗΜΙΑ ΙΩΑΝΝΙΝΩΝ', "value": 'http://www.aeavellas.gr/' },
    { "key": 'ΓΕΩΠΟΝΙΚΟ ΠΑΝΕΠΙΣΤΗΜΙΟ ΑΘΗΝΩΝ', "value": 'https://www2.aua.gr/el' },
    { "key": 'ΕΘΝΙΚΟ ΚΑΠΟΔΙΣΤΡΙΑΚΟ ΠΑΝΕΠΙΣΤΗΜΙΟ ΑΘΗΝΩΝ', "value": 'https://www.uoa.gr/' },
    { "key": 'ΕΛΛΗΝΙΚΟ ΜΕΣΟΓΕΙΑΚΟ ΠΑΝΕΠΙΣΤΗΜΙΟ', "value": 'https://www.hmu.gr/' },
    { "key": 'ΠΑΝΕΠΙΣΤΗΜΙΟ ΑΙΓΑΙΟΥ', "value": 'https://www.aegean.gr/' },
    { "key": 'ΠΑΝΕΠΙΣΤΗΜΙΟ ΘΕΣΣΑΛΙΑΣ', "value": 'https://www.uth.gr/' },
    { "key": 'ΠΑΝΕΠΙΣΤΗΜΙΟ ΜΑΚΕΔΟΝΙΑΣ', "value": 'https://www.uom.gr/sxoles-tmhmata' },
    { "key": 'ΠΑΝΕΠΙΣΤΗΜΙΟ ΠΕΛΟΠΟΝΝΗΣΟΥ', "value": 'https://www.uop.gr/' },
    { "key": 'ΧΑΡΟΚΟΠΕΙΟ ΠΑΝΕΠΙΣΤΗΜΙΟ', "value": 'https://www.hua.gr/index.php/el/' },
    { "key": 'ΑΝΩΤΑΤΗ ΕΚΚΛΗΣΙΑΣΤΙΚΗ ΑΚΑΔΗΜΙΑ ΑΘΗΝΩΝ', "value": 'https://www.aeaa.gr/' },
    { "key": 'ΑΝΩΤΑΤΗ ΕΚΚΛΗΣΙΑΣΤΙΚΗ ΑΚΑΔΗΜΙΑ ΚΡΗΤΗΣ', "value": 'http://www.aeahk.gr/joomla/index.php' },
    { "key": 'ΔΗΜΟΚΡΙΤΕΙΟ ΠΑΝΕΠΙΣΤΗΜΙΟ ΘΡΑΚΗΣ', "value": 'http://duth.gr/' },
    { "key": 'ΕΘΝΙΚΟ ΜΕΤΣΟΒΙΟ ΠΟΛΥΤΕΧΝΕΙΟ', "value": 'https://www.ntua.gr/el/' },
    { "key": 'ΙΟΝΙΟ ΠΑΝΕΠΙΣΤΗΜΙΟ', "value": 'https://ionio.gr/' },
    { "key": 'ΠΑΝΕΠΙΣΤΗΜΙΟ ΔΥΤΙΚΗΣ ΑΤΤΙΚΗΣ', "value": 'https://www.uniwa.gr/' },
    { "key": 'ΠΑΝΕΠΙΣΤΗΜΙΟ ΙΩΑΝΝΙΝΩΝ', "value": 'https://www.uoi.gr/' },
    { "key": 'ΠΑΝΕΠΙΣΤΗΜΙΟ ΠΑΤΡΑΣ', "value": 'https://www.upatras.gr/el' },
    { "key": 'ΠΑΝΤΕΙΟ ΠΑΝΕΠΙΣΤΗΜΙΟ', "value": 'https://www.panteion.gr/index.php/el/' },
    { "key": 'ΑΝΩΤΑΤΗ ΣΧΟΛΗ ΚΑΛΩΝ ΤΕΧΝΩΝ', "value": 'http://www.asfa.gr/' },
    { "key": 'ΑΝΩΤΑΤΗ ΕΚΚΛΗΣΙΑΣΤΙΚΗ ΑΚΑΔΗΜΙΑ ΘΕΣΣΑΛΟΝΙΚΗΣ', "value": 'http://erasmus.aeath.gr/index.php/el/' },
    { "key": 'ΑΚΑΔΗΜΙΕΣ ΕΜΠΟΡΙΚΟΥ ΝΑΥΤΙΚΟΥ', "value": 'https://sites.google.com/site/stratiotikes/home/emporiko-nautiko' },
  ]

  returnLesson(){
    return this.universities[0]['key']
  }

  selectLesson(a: any){}

  ngOnInit(): void {
  }

}
