<div class="content-wrapper container-xxl p-0">
    <div class="content-body">

        <app-content-header [contentHeader]="contentHeader"></app-content-header>

            <div class="desktopRow">
                <div class="card forms1">
                    <!-- ngb-carousel component -->
                    <ngb-carousel [pauseOnHover]="false" *ngIf="carouselImages">
                        <ng-template ngbSlide>
                        <div class="carousel-img-wrapper">
                            <img [src]="carouselImages.one" class="img-fluid" alt="Random second slide" />
                        </div>
                        <div class="carousel-caption">
                            <h3 class="text-white">Αργυρούπολη</h3>
                            <p class="truncate text-white">
                                Κολοκοτρώνης 32 & Ομήρου, Αργυρούπολη, 16451
                            </p>
                        </div>
                        </ng-template>
                        <ng-template ngbSlide>
                        <div class="carousel-img-wrapper">
                            <img [src]="carouselImages.two" class="img-fluid" alt="Random third slide" />
                        </div>
                        <div class="carousel-caption">
                            <h3 class="text-white">Αργυρούπολη</h3>
                            <p class="truncate text-white">
                                Ρουμέλης 15, Αργυρούπολη, 16451.
                            </p>
                        </div>
                        </ng-template>
                    </ngb-carousel>
                    <!-- ngb-carousel component -->
                </div>
                <div class="forms2">
                    <!-- Language selection -->
                    <label>Επιλογή φροντιστηρίου</label>
                    <li ngbDropdown class="nav-item dropdown dropdown-language" style="list-style: none; border-style: solid; border-width: 1px; border-radius: 4px; border-color: lightgray; padding: 2px;">
                        <a class="nav-link dropdown-toggle" id="dropdown-category" ngbDropdownToggle>
                            <span class="selected-language">{{ returnCategory() }}</span></a>
                        <div ngbDropdownMenu aria-labelledby="dropdown-category"
                            style="max-height: 20vh !important; overflow-y: auto;">
                            <div *ngFor="let category of categories">
                                <a ngbDropdownItem (click)="selectCategory(category.value, category.key)">{{category.key}}</a>
                            </div>
                        </div>
                    </li>
                    <!--/ Language selection -->
                    <br>
                    <p><strong>Διεύθυνση</strong>: {{adr.address}}</p>
                    <p><strong>Τηλέφωνο</strong>: {{adr.phone}}</p>
                    <p><strong>Κινητό</strong>: {{adr.cellphone}}</p>
                    <p><strong>Email</strong>: euanagnosto@gmail.com</p>
                    <p><strong>Ωράριο λειτουργίας</strong></p>
                    <p>Δευτέρα: {{adr.monday}}</p>
                    <p>Τρίτη: {{adr.tuesday}}</p>
                    <p>Τετάρτη: {{adr.thursday}}</p>
                    <p>Πέμπτη: {{adr.wednesday}}</p>
                    <p>Παρασκευή: {{adr.friday}}</p>
                    <p>Σάββατο: {{adr.saturday}}</p>
                    <p>Κυριακή: {{adr.sunday}}</p>
                    <div class="form-group">
                        <button type="button" class="btn btn-primary btn-block" (click)="googleMaps()" rippleEffect>Δες στο χάρτη</button>
                    </div>
                </div>
            </div>
    </div>
</div>