import { Component, OnInit } from '@angular/core'
import * as XLSX from 'xlsx';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'app/auth/service/Auth/auth.service';
import { Router } from '@angular/router';
import { NgbDatepickerConfig, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { locale } from '../../translate';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  constructor(
    public dialog: MatDialog,
    private router: Router,
    private toastr: ToastrService,
    public service: AuthService,
    private config: NgbDatepickerConfig,
  ) { 
    
    const current = new Date();
    config.minDate = { year: current.getFullYear(), month: 
    current.getMonth() + 1, day: current.getDate() };
  }
  
  model: NgbDateStruct;
  basicDPdata = {
    year: parseInt(moment(new Date).format('YYYY')),
    month: parseInt(moment(new Date).format('MM')),
    day: parseInt(moment(new Date).format('DD')),
  };

  public contentHeader: object;
  
  // Custom Success
  toastrCustomSuccess(title: string, msg: string) {
    this.toastr.success(msg, title);
  }
  toastrCustomWarning(title: string, msg: string) {
    this.toastr.warning(msg, title);
  }



  checkLogin() {
    let user = localStorage.getItem('euanagnosto_user') || null;

      this.continue_();
  }

  // async sendToWFM_new() {
  //   let d_date = `${this.basicDPdata.year}-${this.basicDPdata.month}-${this.basicDPdata.day}`;
  //   console.log(this.matSelectProvider)
  //   let data:any = await this.service.callFunction('createJobsForProcess', { jobs: this.mappingData, uid: JSON.parse(localStorage.getItem('euanagnosto_user')).data[0].uid, email:  this.matSelectProvider, date: d_date})
  //   if(data.success){
  //     this.toastrCustomSuccess("Success!!!", "Your data has been send!");
  //   }else{
  //     this.toastrCustomWarning("Ooops!!!", "Something went wrong. Please try again!");
  //   }
  // }


  continue_() {
    this.contentHeader = {
      headerTitle: "this.Import",
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: "this.Import",
            isLink: true,
            link: '/'
          }
        ]
      }
    }
    // this.callHistory();
  }

  ngOnInit() {
    this.checkLogin();
  }
  
}
