import { CoreMenu } from '@core/types'

import { locale } from '../translate'

let user = (localStorage.getItem('euanagnosto_user')) || null;
let array: CoreMenu[] = [
  // {
  //   id: 'profile',
  //   title: 'Αρχική', //(localStorage.getItem('locale') || 'gr')=='en' ? locale.EN.My_Profile : locale.GR.My_Profile ,
  //   type: 'item',
  //   icon: 'user',
  //   url: 'profile'
  // },
  // {
  //   id: 'upload',
  //   title: 'Θέματα', //(localStorage.getItem('locale') || 'gr')=='en' ? locale.EN.Import : locale.GR.Import,
  //   type: 'item',
  //   icon: 'edit-3',
  //   url: 'upload'
  // },
  // {
  //   id: 'universities',
  //   title: 'Σχολές', //(localStorage.getItem('locale') || 'gr')=='en' ? locale.EN.Tools : locale.GR.Tools,
  //   type: 'item',
  //   icon: 'home',
  //   url: 'universities'
  // },
  {
    id: 'themata',
    title: 'Πανελλαδικές', //(localStorage.getItem('locale') || 'gr')=='en' ? locale.EN.History : locale.GR.History,
    type: 'item',
    icon: 'award',
    url: 'themata'
  },
  // {
  //   id: 'history',
  //   title: 'Φωτογραφίες', //(localStorage.getItem('locale') || 'gr')=='en' ? locale.EN.History : locale.GR.History,
  //   type: 'item',
  //   icon: 'camera',
  //   url: 'history'
  // },
  {
    id: 'news',
    title: 'Άρθρα', //(localStorage.getItem('locale') || 'gr')=='en' ? locale.EN.History : locale.GR.History,
    type: 'item',
    icon: 'book-open',
    url: 'news'
  },
  {
    id: 'contact',
    title: 'Επικοινωνία', //(localStorage.getItem('locale') || 'gr')=='en' ? locale.EN.History : locale.GR.History,
    type: 'item',
    icon: 'rotate-ccw',
    url: 'contact'
  },
  // {
  //   id: 'history',
  //   title: 'Ερωτήσεις Σ/Λ', //(localStorage.getItem('locale') || 'gr')=='en' ? locale.EN.History : locale.GR.History,
  //   type: 'item',
  //   icon: 'external-link',
  //   url: 'history'
  // },
  // {
  //   id: 'history',
  //   title: 'Μόρια', //(localStorage.getItem('locale') || 'gr')=='en' ? locale.EN.History : locale.GR.History,
  //   type: 'item',
  //   icon: 'pie-chart',
  //   url: 'history'
  // },
// {
//   id: 'uploaded',
//   title: (localStorage.getItem('locale') || 'gr')=='en' ? locale.EN.Uploaded_History : locale.GR.Uploaded_History,
//   type: 'item',
//   icon: 'upload',
//   url: 'uploaded'
// }
];

if(user !== null){
  let users_job = JSON.parse(localStorage.getItem('euanagnosto_user'))["category"];
  if(users_job=='editor' || users_job=='administrator'){
    array.push({
      id: 'upload-article',
      title: 'Μεταφόρτωση άρθρου', //(localStorage.getItem('locale') || 'gr')=='en' ? locale.EN.Pending_actions : locale.GR.Pending_actions,
      type: 'item',
      icon: 'log-in',
      url: 'upload-article'
    })
    array.push({
      id: 'upload-image',
      title: 'Μεταφόρτωση εικόνας', //(localStorage.getItem('locale') || 'gr')=='en' ? locale.EN.Pending_actions : locale.GR.Pending_actions,
      type: 'item',
      icon: 'log-in',
      url: 'upload-image'
    })
    array.push({
      id: 'upload-themata',
      title: 'Μεταφόρτωση θέματος', //(localStorage.getItem('locale') || 'gr')=='en' ? locale.EN.Pending_actions : locale.GR.Pending_actions,
      type: 'item',
      icon: 'log-in',
      url: 'upload-themata'
    })
  }
}

export const menu: CoreMenu[] = array;
