export const locale = {
  lang: 'de',
  data: {
    MENU: {
      HOME: 'Upload',
      SAMPLE: 'Geocode',
      PROFILE: 'Profile',
      HISTORY: 'History'
    }
  }
}
