import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-themata',
  templateUrl: './themata.component.html',
  styleUrls: ['./themata.component.scss']
})
export class ThemataComponent implements OnInit {

  constructor(
    private spinner: NgxSpinnerService,
  ) { }

  public contentHeader: object = {
    headerTitle: "Θέματα Πανελλαδικών",
    actionButton: true,
    breadcrumb: {
      type: '',
      links: [
        {
          name: "Εύρεση"
        }
      ]
    }
  };

  pdfSrc: string = "https://api.euanagnosto.gr/pdf/getpdfbyname?id=math_2019_gel"

  years : Array<number> = [2023,2022,2021,2020,2019,2018,2017,2016,2015,2014,2013,2012,2011,2010]

  lessons: Array<any> = 
  [
    { "key": 'ΑΝΑΠΤΥΞΗ ΕΦΑΡΜΟΓΩΝ ΣΕ ΠΡΟΓΡΑΜΜΑΤΙΣΤΙΚΟ ΠΕΡΙΒΑΛΛΟΝ', "value": 'aepp' },
    { "key": 'ΑΡΧΑΙΑ ΕΛΛΗΝΙΚΗ ΓΛΩΣΣΑ', "value": 'arx' },
    { "key": 'ΑΡΧΕΣ ΟΙΚΟΝΟΜΙΚΗΣ ΘΕΩΡΙΑΣ', "value": 'aoth' },
    { "key": 'ΒΙΟΛΟΓΙΑ ΘΕΤΙΚΩΝ ΣΠΟΥΔΩΝ', "value": 'bio' },
    { "key": 'ΙΣΤΟΡΙΑ', "value": 'ist' },
    { "key": 'ΛΑΤΙΝΙΚΑ', "value": 'lat' },
    { "key": 'ΜΑΘΗΜΑΤΙΚΑ ΠΡΟΣΑΝΑΤΟΛΙΣΜΟΥ', "value": 'math' },
    { "key": 'ΝΕΑ ΕΛΛΗΝΙΚΗ ΓΛΩΣΣΑ ΓΕΝΙΚΗΣ ΠΑΙΔΕΙΑΣ', "value": 'glo' },
    { "key": 'ΦΥΣΙΚΗ', "value": 'fis' },
    { "key": 'ΧΗΜΕΙΑ', "value": 'xim' },
    { "key": '(ΕΠΑΛ) Νέα Ελληνικά', "value": 'glo' },
    { "key": '(ΕΠΑΛ) Μαθηματικά (Άλγεβρα)', "value": 'math' },
    { "key": '(ΕΠΑΛ) Ανατομία-Φυσιολογια ΙΙ', "value": 'anph' },
    { "key": '(ΕΠΑΛ) Αρχές Οικονομικής θεωρίας', "value": 'aoth' },
    { "key": '(ΕΠΑΛ) Δίκτυα υπολογιστών', "value": 'diip' },
    { "key": '(ΕΠΑΛ) Αρχές Βιολογικής Γεωργίας', "value": 'avit' },
    { "key": '(ΕΠΑΛ) Ηλεκτροτεχνία 2', "value": 'ilek' },
    { "key": '(ΕΠΑΛ) Αρχιτεκτονικό σχέδιο', "value": 'arxi' },
    { "key": '(ΕΠΑΛ) Ναυτικό Δικαιο-Διεθνεις', "value": 'nddi' },
    { "key": '(ΕΠΑΛ) Κανονισμοί στη Ναυτιλία', "value": 'kani' },
    { "key": '(ΕΠΑΛ) Εφαρμογες Ιστορία Σύγχρονης Τέχνης', "value": 'eist' },
    { "key": '(ΕΠΑΛ) Υγιεινή', "value": 'igii' },
    { "key": '(ΕΠΑΛ) Προγραμματισμός Υπολογιστών', "value": 'prip' },
    { "key": '(ΕΠΑΛ) Αρχές Οργάνωσης και Διοίκησης', "value": 'aode' },
    { "key": '(ΕΠΑΛ) Στοιχεία Μηχανών', "value": 'stmi' },
    { "key": '(ΕΠΑΛ) Ηλεκτρικές Μηχανές', "value": 'ilmi' },
    { "key": '(ΕΠΑΛ) Σύγχρονες Γεωργικές Επιχειρήσεις', "value": 'sgep' },
    { "key": '(ΕΠΑΛ) Ναυσιπλοϊα ΙΙ', "value": 'naus' },
    { "key": '(ΕΠΑΛ) Ναυτικές Μηχανές', "value": 'nami' },
    { "key": '(ΕΠΑΛ) Τεχνολογία Υλικών', "value": 'teul' },
    { "key": '(ΕΠΑΛ) Οικοδομική', "value": 'oik' },
    { "key": '(ΕΠΑΛ) Μηχανές Εσωτερικής Καύσης', "value": 'mek' },
    { "key": '(ΕΠΑΛ) Ψηφιακα Συστήματα', "value": 'pssi' },
    { "key": '(ΕΠΑΛ) Στοιχεία Ψυξης-Κλιματισμου', "value": 'sckl' },
    { "key": '(ΕΠΑΛ) Κινητήρες Αεροσκαφών', "value": 'kina' },
    { "key": '(ΕΠΑΛ) Στοιχεία Σχεδιασμού Κεντρικών Θεμανσεων', "value": 'sskt' },
  ]

  categories: Array<any> = 
  [
    { "key": 'ΓΕΝΙΚΑ ΗΜΕΡΗΣΙΑ ΛΥΚΕΙΑ', "value": 'gel' },
    { "key": 'ΕΣΠΕΡΙΝΑ ΛΥΚΕΙΑ', "value": 'esp' },
    { "key": 'ΟΜΟΓΕΝΩΝ', "value": 'omo' },
    { "key": 'ΟΕΦΕ', "value": 'oefe' },
    { "key": 'ΕΠΑΝΑΛΗΠΤΙΚΕΣ ΓΕΝΙΚΑ ΗΜΕΡΗΣΙΑ ΛΥΚΕΙΑ', "value": 'gel_epa' },
    { "key": 'ΕΠΑΝΑΛΗΠΤΙΚΕΣ ΕΣΠΕΡΙΝΑ', "value": 'esp_epa' },
    { "key": 'ΕΠΑΓΓΕΛMAΤΙΚΑ', "value": 'epal' },
  ]

  selected_year: number = 2023;
  selected_lesson: string = 'math';
  selected_category: string = 'gel';
  selected_lesson_: string = 'ΜΑΘΗΜΑΤΙΚΑ ΠΡΟΣΑΝΑΤΟΛΙΣΜΟΥ';
  selected_category_: string = 'ΓΕΝΙΚΑ ΗΜΕΡΗΣΙΑ ΛΥΚΕΙΑ';

  pdfError : boolean = false;

  returnYear(){
    return this.selected_year || this.years[0]
  }
  returnLesson(){
    return this.selected_lesson_ || this.lessons[0]['key']
  }
  returnCategory(){
    return this.selected_category_ || this.categories[0]['key']
  }

  selectYear(a: any){
    this.selected_year = a;
  }
  selectLesson(a: any, b:string){
    this.selected_lesson = a;
    this.selected_lesson_ = b;
  }
  selectCategory(a: any, b:string){
    this.selected_category = a;
    this.selected_category_ = b;
  }

  find(){
    this.spinner.show()
    this.pdfError = false;
    let file = this.selected_lesson+"_"+this.selected_year+"_"+this.selected_category;
    let pdfUrl = `https://api.euanagnosto.gr/pdf/getpdfbyname?id=${file}`
    
    this.pdfSrc = pdfUrl;
    setTimeout(() => {
    this.spinner.hide()
    }, 1500);
  }

  handleError(error: any) {
    this.pdfError = true;
    this.spinner.hide()
  }

  ngOnInit(): void {
  }

}
