import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/auth/service/Auth/auth.service';

import { locale } from '../../../translate';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  constructor(
    private router: Router
  ) {
    
  }

  
  ngOnInit(): void {
    this.checkLogin()
  }

  checkLogin(){
    let user = localStorage.getItem('euanagnosto_user') || null;
    if(!user){
      this.router.navigate(['/pages/authentication/login-v2'])
    }else{
      
    }
  }

}
