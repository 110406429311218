<div class="content-wrapper container-xxl p-0">
    <div class="content-body">

        <app-content-header [contentHeader]="contentHeader"></app-content-header>

        <div style="display: flex; flex-direction: row; width: 100%; justify-content: space-between;">
            <div style="display: flex; flex-direction: column; width: 70%;">
                    
                    <div class="form-group">
                        <label for="basicInputFile">Επιλογή εικόνας</label>
                        <div class="custom-file">
                            <input type="file" class="custom-file-input" id="customFile" />
                            <label class="custom-file-label" for="customFile">Μεταφόρτωση εικόνας</label>
                        </div>
                    </div>

                <div style="margin-top: 16px">
                    <button type="button" class="btn btn-primary" rippleEffect>Αποθήκευση</button>
                </div>
            </div>
            <img src="../../../../assets/images/pages/card-image-4.jpg" style="width: 25%; max-height: 39vh;" />
        </div>
    </div>
</div>