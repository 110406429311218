import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/auth/service/Auth/auth.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-upload-themata',
  templateUrl: './upload-themata.component.html',
  styleUrls: ['./upload-themata.component.scss']
})
export class UploadThemataComponent implements OnInit {

  constructor(
    private toastr: ToastrService,
    private service: AuthService
  ) { }

  public contentHeader: object = {
    headerTitle: "Μεταφόρτωση εικόνας",
    actionButton: true,
    breadcrumb: {
      type: '',
      links: [
        {
          name: "Νέο"
        }
      ]
    }
  };

  years : Array<number> = [2023,2022,2021,2020,2019,2018,2017,2016,2015,2014,2013,2012,2011,2010]

  lessons: Array<any> = 
  [
    { "key": 'ΑΝΑΠΤΥΞΗ ΕΦΑΡΜΟΓΩΝ ΣΕ ΠΡΟΓΡΑΜΜΑΤΙΣΤΙΚΟ ΠΕΡΙΒΑΛΛΟΝ', "value": 'aepp' },
    { "key": 'ΑΡΧΑΙΑ ΕΛΛΗΝΙΚΗ ΓΛΩΣΣΑ', "value": 'arx' },
    { "key": 'ΑΡΧΕΣ ΟΙΚΟΝΟΜΙΚΗΣ ΘΕΩΡΙΑΣ', "value": 'aoth' },
    { "key": 'ΒΙΟΛΟΓΙΑ ΘΕΤΙΚΩΝ ΣΠΟΥΔΩΝ', "value": 'bio' },
    { "key": 'ΙΣΤΟΡΙΑ', "value": 'ist' },
    { "key": 'ΛΑΤΙΝΙΚΑ', "value": 'lat' },
    { "key": 'ΜΑΘΗΜΑΤΙΚΑ ΠΡΟΣΑΝΑΤΟΛΙΣΜΟΥ', "value": 'math' },
    { "key": 'ΝΕΑ ΕΛΛΗΝΙΚΗ ΓΛΩΣΣΑ ΓΕΝΙΚΗΣ ΠΑΙΔΕΙΑΣ', "value": 'glo' },
    { "key": 'ΦΥΣΙΚΗ', "value": 'fis' },
    { "key": 'ΧΗΜΕΙΑ', "value": 'xim' },
    { "key": '(ΕΠΑΛ) Νέα Ελληνικά', "value": 'glo' },
    { "key": '(ΕΠΑΛ) Μαθηματικά (Άλγεβρα)', "value": 'math' },
    { "key": '(ΕΠΑΛ) Ανατομία-Φυσιολογια ΙΙ', "value": 'anph' },
    { "key": '(ΕΠΑΛ) Αρχές Οικονομικής θεωρίας', "value": 'aoth' },
    { "key": '(ΕΠΑΛ) Δίκτυα υπολογιστών', "value": 'diip' },
    { "key": '(ΕΠΑΛ) Αρχές Βιολογικής Γεωργίας', "value": 'avit' },
    { "key": '(ΕΠΑΛ) Ηλεκτροτεχνία 2', "value": 'ilek' },
    { "key": '(ΕΠΑΛ) Αρχιτεκτονικό σχέδιο', "value": 'arxi' },
    { "key": '(ΕΠΑΛ) Ναυτικό Δικαιο-Διεθνεις', "value": 'nddi' },
    { "key": '(ΕΠΑΛ) Κανονισμοί στη Ναυτιλία', "value": 'kani' },
    { "key": '(ΕΠΑΛ) Εφαρμογες Ιστορία Σύγχρονης Τέχνης', "value": 'eist' },
    { "key": '(ΕΠΑΛ) Υγιεινή', "value": 'igii' },
    { "key": '(ΕΠΑΛ) Προγραμματισμός Υπολογιστών', "value": 'prip' },
    { "key": '(ΕΠΑΛ) Αρχές Οργάνωσης και Διοίκησης', "value": 'aode' },
    { "key": '(ΕΠΑΛ) Στοιχεία Μηχανών', "value": 'stmi' },
    { "key": '(ΕΠΑΛ) Ηλεκτρικές Μηχανές', "value": 'ilmi' },
    { "key": '(ΕΠΑΛ) Σύγχρονες Γεωργικές Επιχειρήσεις', "value": 'sgep' },
    { "key": '(ΕΠΑΛ) Ναυσιπλοϊα ΙΙ', "value": 'naus' },
    { "key": '(ΕΠΑΛ) Ναυτικές Μηχανές', "value": 'nami' },
    { "key": '(ΕΠΑΛ) Τεχνολογία Υλικών', "value": 'teul' },
    { "key": '(ΕΠΑΛ) Οικοδομική', "value": 'oik' },
    { "key": '(ΕΠΑΛ) Μηχανές Εσωτερικής Καύσης', "value": 'mek' },
    { "key": '(ΕΠΑΛ) Ψηφιακα Συστήματα', "value": 'pssi' },
    { "key": '(ΕΠΑΛ) Στοιχεία Ψυξης-Κλιματισμου', "value": 'sckl' },
    { "key": '(ΕΠΑΛ) Κινητήρες Αεροσκαφών', "value": 'kina' },
    { "key": '(ΕΠΑΛ) Στοιχεία Σχεδιασμού Κεντρικών Θεμανσεων', "value": 'sskt' },
  ]

  categories: Array<any> = 
  [
    { "key": 'ΓΕΝΙΚΑ ΗΜΕΡΗΣΙΑ ΛΥΚΕΙΑ', "value": 'gel' },
    { "key": 'ΕΣΠΕΡΙΝΑ ΛΥΚΕΙΑ', "value": 'esp' },
    { "key": 'ΟΜΟΓΕΝΩΝ', "value": 'omo' },
    { "key": 'ΟΕΦΕ', "value": 'oefe' },
    { "key": 'ΕΠΑΝΑΛΗΠΤΙΚΕΣ ΓΕΝΙΚΑ ΗΜΕΡΗΣΙΑ ΛΥΚΕΙΑ', "value": 'gel_epa' },
    { "key": 'ΕΠΑΝΑΛΗΠΤΙΚΕΣ ΕΣΠΕΡΙΝΑ', "value": 'esp_epa' },
    { "key": 'ΕΠΑΓΓΕΛMAΤΙΚΑ', "value": 'epal' },
  ]

  selectedFile: File;
  imageURL: string = "../../../../assets/images/pages/card-image-4.jpg";

  selectedYear = 0;
  selectedLesson = '';
  selectedCategory = '';

  returnYear(){
    return this.years[0]
  }
  returnLesson(){
    return this.lessons[0]['key']
  }
  returnCategory(){
    return this.categories[0]['key']
  }

  selectYear(a: any){this.selectedYear = a}
  selectLesson(a: any){this.selectedLesson = a}
  selectCategory(a: any){this.selectedCategory = a}

  ngOnInit(): void {
  }

  async save(){  
    if(this.selectedYear == 0){
      this.toastrCustomWarning("Validation error!!", "You have to select a year")
    }else if(this.selectedLesson == ''){
      this.toastrCustomWarning("Validation error!!", "You have to select a lesson")
    }else if(this.selectedCategory == ''){
      this.toastrCustomWarning("Validation error!!", "You have to select a category")
    }else{
      let name = `${this.selectedLesson}_${this.selectedYear}_${this.selectedCategory}`
      let res:any = await this.service.callFunction('addthemata', {name: name, pdf: this.imageURL})
      if(res.success){
        this.toastrCustomSuccess("Success!!", "Upload completed!")
      }else{
        this.toastrCustomWarning("Ooops!!", "Something went wrong!")
      }
    }
  }

  handleFileInput(event: any){
    this.selectedFile = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.imageURL = reader.result as string;
    }
    reader.readAsDataURL(this.selectedFile);
  }

  // Custom Success
  toastrCustomSuccess(title: string, msg: string) {
    this.toastr.success(msg, title);
  }
  toastrCustomWarning(title: string, msg: string) {
    this.toastr.warning(msg, title);
  }
}