import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/auth/service/Auth/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-arthra',
  templateUrl: './arthra.component.html',
  styleUrls: ['./arthra.component.scss']
})
export class ArthraComponent implements OnInit {

  constructor(
    private service: AuthService,
    private spinner: NgxSpinnerService,
    private router: Router
  ) { }

  public contentHeader: object = {
    headerTitle: "Ενημερώσεις",
    actionButton: true,
    breadcrumb: {
      type: '',
      links: [
        {
          name: "Νέα"
        }
      ]
    }
  };
  pages = [1, 2, 3, 4, 5, 6, 7];
  currentPage = 1;
  articles: Array<any> = [];

  ngOnInit(): void {
    this.callArticles(this.currentPage)
  }

  async callArticles(id: number){
    this.spinner.show()
    let res: any = await this.service.callFunction('getallarticles', {padding: id});
    this.articles = res
    this.spinner.hide()
  }

  showArticle(id: number){
    this.router.navigate(['/news/'+id])
  }
  onPageClick(page: number) {
    if(page==0 || page==8){return}
    else{this.currentPage = page; this.callArticles(this.currentPage);}
  }
}
