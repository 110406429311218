import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { CoreCommonModule } from '@core/common.module';

import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { GoogleMapsModule } from '@angular/google-maps';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SampleComponent } from './sample.component';
import { HomeComponent } from './home.component';
import { MatDialogModule } from '@angular/material/dialog';
import { DashboardComponent } from '../pages/dashboard/dashboard.component';
import { MatSelectModule } from '@angular/material/select';
import { UploadArticleComponent } from '../pages/upload-article/upload-article.component';
import { UploadImageComponent } from '../pages/upload-image/upload-image.component';
import { UploadThemataComponent } from '../pages/upload-themata/upload-themata.component';
import { UniversitiesComponent } from '../pages/universities/universities.component';
import { ThemataComponent } from '../pages/themata/themata.component';
import { ArthraComponent } from '../pages/arthra/arthra.component';
import { ArthroComponent } from '../pages/arthro/arthro.component';
import { ContactComponent } from '../pages/contact/contact.component';
import { EditArthroComponent } from '../pages/edit-arthro/edit-arthro.component';


const routes = [
  {
    path: 'geocode',
    component: SampleComponent,
    data: { animation: 'sample' }
  },
  {
    path: 'upload',
    component: HomeComponent,
    data: { animation: 'home' }
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    data: { animation: 'dashboard' }
  },
  {
    path: 'edit-article/:id',
    component: EditArthroComponent,
    data: { animation: 'edit-article' }
  },
  {
    path: 'upload-article',
    component: UploadArticleComponent,
    data: { animation: 'upload-article' }
  },
  {
    path: 'upload-image',
    component: UploadImageComponent,
    data: { animation: 'upload-image' }
  },
  {
    path: 'upload-themata',
    component: UploadThemataComponent,
    data: { animation: 'upload-themata' }
  },
  {
    path: 'universities',
    component: UniversitiesComponent,
    data: { animation: 'universities' }
  },
  {
    path: 'themata',
    component: ThemataComponent,
    data: { animation: 'themata' }
  },
  {
    path: 'contact',
    component: ContactComponent,
    data: { animation: 'contact' }
  },
  {
    path: 'news',
    component: ArthraComponent,
    data: { animation: 'news' }
  },
  {
    path: 'news/:id',
    component: ArthroComponent,
    data: { animation: 'news' }
  },
];

@NgModule({
  declarations: [SampleComponent, HomeComponent],
  imports: [RouterModule.forChild(routes), ContentHeaderModule,
    CardSnippetModule,NgbModule,
    MatSelectModule,
    NgxDatatableModule, MatDialogModule, TranslateModule, CoreCommonModule, GoogleMapsModule, LeafletModule],
  exports: [SampleComponent, HomeComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
})
export class SampleModule {}
