import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.scss']
})
export class UploadImageComponent implements OnInit {

  constructor() { }

  public contentHeader: object = {
    headerTitle: "Μεταφόρτωση εικόνας",
    actionButton: true,
    breadcrumb: {
      type: '',
      links: [
        {
          name: "Νέο"
        }
      ]
    }
  };

  ngOnInit(): void {
  }

}
