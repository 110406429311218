<div class="content-wrapper container-xxl p-0">
    <div class="content-body"> 

        <app-content-header [contentHeader]="contentHeader"></app-content-header>

                <!-- Blog List -->
                <div class="blog-list-wrapper">
                    <!-- Blog List Items -->
                    <div class="row">
                      <div class="col-md-4 col-12" *ngFor="let art of articles">
                        <div class="card" (click)="showArticle(art.id)">
                          <a>
                            <img class="card-img-top img-fluid" style="height: 300px; object-fit: cover;" [src]="art.image" alt="Blog Post pic" />
                          </a>
                          <div class="card-body">
                            <h4 class="card-title">
                              <p class="blog-title-truncate text-body-heading" style="display: -webkit-box;
                              -webkit-line-clamp: 3; height: 63px; overflow-y: hidden;
                              -webkit-box-orient: vertical;" >{{ art.name }}</p>
                            </h4>
                            <p class="card-text blog-content-truncate" style="height: 102px; overflow-y: hidden;" [innerHTML]="art.text"></p>

                            <div class="media">
                              <!-- <div class="avatar mr-50">
                                <small class="text-muted">{{ art.date | date: 'd/mm/yyyy' }}</small>
                              </div> -->
                              <div class="media-body">
                                <!-- <span class="text-muted ml-50 mr-25">|</span> -->
                                <small class="text-muted">{{ art.date | date: 'd/M/yyyy' }}</small>
                              </div>
                            </div>
                            <hr />
                            <div class="d-flex justify-content-between align-items-center">
                              <a>
                                <div class="d-flex align-items-center">
                                  <i data-feather="message-square" class="font-medium-1 text-body mr-50"></i>
                                  <span class="text-body font-weight-bold">{{ art.views }} Views</span>
                                </div>
                              </a>
                              <a class="font-weight-bold">Read More</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--/ Blog List Items -->
          
                    <!-- Pagination -->
                    <div class="row">
                      <div class="col-12">
                        <nav aria-label="Page navigation">
                          <ul class="pagination justify-content-center mt-2">
                            <li class="page-item prev-item"><a class="page-link" (click)="onPageClick(currentPage-1)"></a></li>
                            <li class="page-item" *ngFor="let page of pages; let i = index"
                                [class.active]="i+1 === currentPage">
                                <a class="page-link" (click)="onPageClick(i+1)">{{ i+1 }}</a>
                            </li>
                            <li class="page-item next-item"><a class="page-link"  (click)="onPageClick(currentPage+1)"></a></li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                    <!--/ Pagination -->
                  </div>
                  <!--/ Blog List -->
    </div>
</div>
<ngx-spinner style="border-radius: 8px;" bdColor = "rgba(0, 0, 0, 0.4)" size = "medium" color = "#fff" type = "ball-spin" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner>