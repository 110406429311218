import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'app/auth/service/Auth/auth.service';
import { BeforeOpenEvent } from '@sweetalert2/ngx-sweetalert2';
import Swal from 'sweetalert2';
import { id } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-arthro',
  templateUrl: './arthro.component.html',
  styleUrls: ['./arthro.component.scss']
})
export class ArthroComponent implements OnInit {

  constructor(
    private service: AuthService,
    private _route: ActivatedRoute,
    private router: Router
  ) { }

  public contentHeader: object = {
    headerTitle: "Ενημερώσεις",
    actionButton: true,
    breadcrumb: {
      type: '',
      links: [
        {
          name: "Νέα"
        }
      ]
    }
  };

  article: any = {};
  id: number = 0;

  ConfirmColorOpen() {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then(async (result) => {
      if (result.value) {
        await this.service.callFunction('deletearticle', {id: this.id})
        Swal.fire({
          title: 'Deleted!',
          text: 'Your article has been deleted.',
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-success'
          }
        });

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({
          title: 'Cancelled',
          text: 'Your article is safe :)',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-success'
          }
        });
      }
    });
  }

  edit(){
    this.router.navigate(['edit-article/'+this.id])
  }

  isLoggedIn(){
    let user = localStorage.getItem('euanagnosto_user') || ''
    if(user == ''){
      return false
    }else{
      return true
    }
  }

  async ngOnInit(): Promise<void> {
    this.id = this._route.url["_value"][1].path;
    let res: any = await this.service.callFunction('getarticlebyid', {id: this.id});
    this.article = res[0];
  }
}
