import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  constructor() { }
  
  public contentHeader: object = {
    headerTitle: "Επικοινωνία",
    actionButton: true,
    breadcrumb: {
      type: '',
      links: [
        {
          name: ""
        }
      ]
    }
  };

  categories: Array<any> = 
  [
    { "key": 'Κολοκοτρώνης 32 & Ομήρου, Αργυρούπολη, 16451', "value": 1 },
    { "key": 'Ρουμέλης 15, Αργυρούπολη, 16451', "value": 2 },
  ]
  selected_category_: string;
  selected_category: number;
  adr: any = {
    id: 1,
    address: 'Κολοκοτρώνης 32 & Ομήρου, Αργυρούπολη, 16451',
    phone: '2114144819',
    cellphone: '6938298348',
    monday: '15:00 - 22:00',
    tuesday: '15:00 - 22:00',
    thursday: '15:00 - 22:00',
    wednesday: '15:00 - 22:00',
    friday: '15:00 - 22:00',
    saturday: '09:00 - 15:00',
    sunday: 'Κλειστά',
    pin: '',
  }

  public carouselImages = {
    one: 'assets/images/front1.png',
    two: 'assets/images/front2.jpg',
  };

  front = [
    {
      id: 1,
      address: 'Κολοκοτρώνης 32 & Ομήρου, Αργυρούπολη, 16451',
      phone: '2114144819',
      cellphone: '6938298348',
      monday: '15:00 - 22:00',
      tuesday: '15:00 - 22:00',
      thursday: '15:00 - 22:00',
      wednesday: '15:00 - 22:00',
      friday: '15:00 - 22:00',
      saturday: '09:00 - 15:00',
      sunday: 'Κλειστά',
      pin: '',
    },
    {
      id: 2,
      address: 'Ρουμέλης 15, Αργυρούπολη, 16451',
      phone: '2110122000',
      cellphone: '',
      monday: '15:00 - 22:00',
      tuesday: '15:00 - 22:00',
      thursday: '15:00 - 22:00',
      wednesday: '15:00 - 22:00',
      friday: '15:00 - 22:00',
      saturday: '09:00 - 15:00',
      sunday: 'Κλειστά',
      pin: '',
    },
  ]


  googleMaps(){
    window.open("https://www.google.com/maps/place/%CE%A6%CF%81%CE%BF%CE%BD%CF%84%CE%B9%CF%83%CF%84%CE%AE%CF%81%CE%B9%CE%BF+%CE%95%CF%85%CE%B1%CE%BD%CE%AC%CE%B3%CE%BD%CF%89%CF%83%CF%84%CE%BF/@37.9059915,23.7549334,17z/data=!3m1!4b1!4m6!3m5!1s0x14a1bfca30298141:0x76a814e4cebda083!8m2!3d37.9059915!4d23.7549334!16s%2Fg%2F11j0qpqtb7", "_blank");
  }

  ngOnInit(): void {

  }

  selectCategory(a:number , b:string){
    this.selected_category = a;
    this.selected_category_ = b;
    this.adr = this.front[a-1];
  }

  returnCategory(){
    return this.selected_category_ || this.categories[0]['key']
  }
}
