<div class="content-wrapper container-xxl p-0">
    <div class="content-body">

        <div style="display: flex; flex-direction: row; justify-content: space-between; width: 100%;">
            <app-content-header [contentHeader]="contentHeader"></app-content-header>

            <div *ngIf="isLoggedIn()" style="display: flex; flex-direction: row;">
                <div>
                    <button type="button" class="btn btn-primary" (click)="edit()" rippleEffect>Επεξεργασία</button>
                </div>
                <div style="margin-left: 16px">
                    <button type="button" class="btn btn-danger" (click)="ConfirmColorOpen()" rippleEffect>Διαγραφή</button>
                </div>
            </div>
        </div>

        <div class="desktopRow">
            <div class="forms1">
                <p class="card-text blog-content-truncate">Δημοσιεύθηκε: {{article.date | date: 'd/M/yyyy'}}</p>
                <h4 class="card-title">{{article.name}}</h4>
                <p class="card-text blog-content-truncate" [innerHTML]="article.text"></p>
            </div>
            <img [src]="article.image" class="forms2" />
        </div>
    </div>
</div>
<!-- <swal #SweetAlertConfirmColor (open)="ConfirmColorOpen()"></swal> -->
