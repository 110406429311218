import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from 'app/auth/service/Auth/auth.service';
import { Editor } from 'ngx-editor';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-upload-article',
  templateUrl: './upload-article.component.html',
  styleUrls: ['./upload-article.component.scss']
})
export class UploadArticleComponent implements OnInit, OnDestroy {

  constructor(
    private toastr: ToastrService,
    private service: AuthService
  ) {  }

  editor: Editor;
  html:any = '';
  public contentHeader: object = {
    headerTitle: "Δημιουργία άρθρου",
    actionButton: true,
    breadcrumb: {
      type: '',
      links: [
        {
          name: "Νέο",
          isLink: true,
          link: '/'
        }
      ]
    }
  };
  selectedFile: File;
  imageURL: string = "../../../../assets/images/pages/card-image-4.jpg";

  handleFileInput(event: any){
    this.selectedFile = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.imageURL = reader.result as string;
    }
    reader.readAsDataURL(this.selectedFile);
  }

  async save(){
    let title = (<HTMLInputElement>document.getElementById('basicInput')).value.trim();
    let editor = this.html;
    let image = this.imageURL;

    if(title == ''){
      this.toastrCustomWarning("Validation error!!", "You have to add a title")
    }else if(image.includes('../../')){
      this.toastrCustomWarning("Validation error!!", "You have to select an image")
    }else{
      console.log('title ', title)
      console.log('editor ', editor)
      console.log('image ', image)
      let res:any = await this.service.callFunction('addarticle', {
        "text": editor,
        "name": title,
        "category": "ΓΕΝΙΚΑ",
        "image": image,
        "name1": "",
        "name2": "",
        "name3": ""
      })
      if(res.success){
        this.toastrCustomSuccess("Success!!", "Upload has been completed")
      }else{
        this.toastrCustomWarning("Ooops!!", "Something went wrong")
      }
    }
  }


  ngOnInit(): void {
    this.editor = new Editor();
  }
  ngOnDestroy(): void {
    this.editor.destroy();
  }
  // Custom Success
  toastrCustomSuccess(title: string, msg: string) {
    this.toastr.success(msg, title);
  }
  toastrCustomWarning(title: string, msg: string) {
    this.toastr.warning(msg, title);
  }
}
