import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'app/auth/service/Auth/auth.service';
import { Editor } from 'ngx-editor';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-edit-arthro',
  templateUrl: './edit-arthro.component.html',
  styleUrls: ['./edit-arthro.component.scss']
})
export class EditArthroComponent implements OnInit {

  constructor(
    private service: AuthService,
    private _route: ActivatedRoute,
    private toastr: ToastrService,
  ) { }
  editor: Editor;
  html:any = '';
  public contentHeader: object = {
    headerTitle: "Ενημέρωση άρθρου",
    actionButton: true,
    breadcrumb: {
      type: '',
      links: [
        {
          name: "Νέο",
          isLink: true,
          link: '/'
        }
      ]
    }
  };
  article: any = {};
  id: number = 0;
  selectedFile: File;
  imageURL: string = "../../../../assets/images/pages/card-image-4.jpg";

  handleFileInput(event: any){
    this.selectedFile = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.imageURL = reader.result as string;
    }
    reader.readAsDataURL(this.selectedFile);
  }

  async save(){
    let title = (<HTMLInputElement>document.getElementById('basicInput')).value.trim();
    let editor = this.html;
    
    if(title == ''){
      this.toastrCustomWarning("Validation error!!", "You have to add a title")
    }else{
      let res:any = await this.service.callFunction('putarticletext', {
        "id": this.id,
        "text": editor
      })
      await this.service.callFunction('putarticlename', {
        "id": this.id,
        "text": title
      })
      if(res.success){
        this.toastrCustomSuccess("Success!!", "Upload has been completed")
      }else{
        this.toastrCustomWarning("Ooops!!", "Something went wrong")
      }
    }
  }

  async ngOnInit(): Promise<void> {
    this.editor = new Editor();
    
    this.id = this._route.url["_value"][1].path;
    let res: any = await this.service.callFunction('getarticlebyid', {id: this.id});
    this.article = res[0];
    this.html = this.article.text
    this.imageURL = this.article.image
  }
  ngOnDestroy(): void {
    this.editor.destroy();
  }
  // Custom Success
  toastrCustomSuccess(title: string, msg: string) {
    this.toastr.success(msg, title);
  }
  toastrCustomWarning(title: string, msg: string) {
    this.toastr.warning(msg, title);
  }

}
