<div class="content-wrapper container-xxl p-0">
    <div class="content-body">

        <app-content-header [contentHeader]="contentHeader"></app-content-header>

        <div style="display: flex; flex-direction: row; width: 100%; justify-content: space-between;">
            <div style="display: flex; flex-direction: column; width: 70%;">
                <div class="row match-height" style="margin: 0px;">
                    <div class="form-group" style="width: calc(100% - 424px);">
                        <label for="basicInput">Τίτλος άρθρου</label>
                        <input type="text" class="form-control" id="basicInput" placeholder="Επιλογή τίτλου" />
                    </div>
            
                    <div class="form-group" style="margin-left: 16px;">
                        <label for="basicInputFile">Επιλογή εικόνας</label>
                        <div class="custom-file">
                            <input type="file" class="custom-file-input" id="customFile" (change)="handleFileInput($event)">
                            <label class="custom-file-label" for="customFile">Μεταφόρτωση εικόνας</label>
                        </div>
                    </div>
                </div>
        
                <div class="NgxEditor__Wrapper form-group" >
                    <ngx-editor-menu [editor]="editor"> </ngx-editor-menu>
                    <ngx-editor [editor]="editor" [(ngModel)]="html" [disabled]="false" [placeholder]="'Ξεκινήστε από εδώ...'"></ngx-editor>
                </div>
                <!-- <div [innerHTML]="html"></div> -->
                <div style="margin-top: 16px">
                    <button type="button" class="btn btn-primary" (click)="save()" rippleEffect>Αποθήκευση</button>
                </div>
            </div>
            <img [src]="imageURL" style="width: 25%; max-height: 39vh;" />
        </div>
    </div>
</div>