import { Component, OnInit } from '@angular/core'
import * as L from 'leaflet'; 
import * as XLSX from 'xlsx';

import * as FileSaver from 'file-saver';
import { locale as en } from './i18n/en'
import { locale as fr } from './i18n/fr'
import { locale as de } from './i18n/de'
import { locale as pt } from './i18n/pt'

import { CoreTranslationService } from '@core/services/translation.service'
import { AuthService } from 'app/auth/service/Auth/auth.service';
import { Router } from '@angular/router';
import * as moment from 'moment'
import { locale } from '../../translate';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-sample',
  templateUrl: './sample.component.html',
  styleUrls: ['./sample.component.scss']
})
export class SampleComponent implements OnInit {
  public contentHeader: object;

  constructor(private _coreTranslationService: CoreTranslationService, private service: AuthService,
    private router: Router) {

    }
  

  checkLogin(){
    let user = localStorage.getItem('euanagnosto_user') || null;
    if(!user){
      this.router.navigate(['/pages/authentication/login-v2'])
    }else{
      this.continue_()
    }
  }
  
  continue_(){

    this.contentHeader = {
      headerTitle: "this.Geocode",
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: "this.Geocode",
            isLink: true,
            link: '/'
          }
        ]
      }
    }
  }
  
  ngOnInit() {
    this.checkLogin()
  }
}
