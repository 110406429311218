<div class="content-wrapper container-xxl p-0">
    <div class="content-body">

        <app-content-header [contentHeader]="contentHeader"></app-content-header>

        <div style="display: flex; flex-direction: row; width: 100%; justify-content: space-between;">
            <div style="display: flex; flex-direction: column; width: 100%;">

                <div class="form-group">
                    <label for="basicInputFile">Επιλογή εικόνας</label>
                    <div class="custom-file">
                        <input type="file" class="custom-file-input" id="customFile" (change)="handleFileInput($event)" />
                        <label class="custom-file-label" for="customFile">Μεταφόρτωση αρχείου</label>
                    </div>
                </div>

                <!-- Language selection -->
                <label>Έτος</label>
                <li ngbDropdown class="nav-item dropdown dropdown-language" style="list-style: none; border-style: solid; border-width: 1px; border-radius: 4px; border-color: lightgray; padding: 2px;">
                    <a class="nav-link dropdown-toggle" id="dropdown-year" ngbDropdownToggle>
                        <span class="selected-language">{{ returnYear() }}</span></a>
                    <div ngbDropdownMenu aria-labelledby="dropdown-year"
                        style="height: 20vh !important; overflow-y: auto;">
                        <div *ngFor="let year of years">
                            <a ngbDropdownItem (click)="selectYear(year)">{{year}}</a>
                        </div>
                    </div>
                </li>
                <!--/ Language selection -->
                <br>
                <!-- Language selection -->
                <label>Μάθημα</label>
                <li ngbDropdown class="nav-item dropdown dropdown-language" style="list-style: none; border-style: solid; border-width: 1px; border-radius: 4px; border-color: lightgray; padding: 2px;">
                    <a class="nav-link dropdown-toggle" id="dropdown-lesson" ngbDropdownToggle>
                        <span class="selected-language">{{ returnLesson() }}</span></a>
                    <div ngbDropdownMenu aria-labelledby="dropdown-lesson"
                        style="max-height: 20vh !important; overflow-y: auto;">
                        <div *ngFor="let lesson of lessons">
                            <a ngbDropdownItem (click)="selectLesson(lesson.value)">{{lesson.key}}</a>
                        </div>
                    </div>
                </li>
                <!--/ Language selection -->
                <br>
                <!-- Language selection -->
                <label>Κατηγορία</label>
                <li ngbDropdown class="nav-item dropdown dropdown-language" style="list-style: none; border-style: solid; border-width: 1px; border-radius: 4px; border-color: lightgray; padding: 2px;">
                    <a class="nav-link dropdown-toggle" id="dropdown-category" ngbDropdownToggle>
                        <span class="selected-language">{{ returnCategory() }}</span></a>
                    <div ngbDropdownMenu aria-labelledby="dropdown-category"
                        style="max-height: 20vh !important; overflow-y: auto;">
                        <div *ngFor="let category of categories">
                            <a ngbDropdownItem (click)="selectCategory(category.value)">{{category.key}}</a>
                        </div>
                    </div>
                </li>
                <!--/ Language selection -->

                <div style="margin-top: 16px">
                    <button type="button" class="btn btn-primary" rippleEffect (click)="save()">Αποθήκευση</button>
                </div>
            </div>
        </div>
    </div>
</div>